import React, { Component } from "react"
import {Link} from 'gatsby'

import Layout from "../components/layout"
import GatedContent from "../components/gatedcontent"


const contentlist = [
			{
				name: "NegosyoNow",
				sublist: [
					{
						name: "Product Sections Design Document",
						url: "https://docs.google.com/presentation/d/1YzDjXSPDLu8aPOGWeljjqo2yBNJpxKheHF2qsFGnqbs"
					},
					{
						name: "Form Builder Design Document",
						url: "https://docs.google.com/presentation/d/1Stjs9eKpB0olysy_cv7Gh4cMv5Jr_Tlve3bWl451-q8"
					},
					{
						name: "APIs",
						url: "../api/negosyonow"
					},
					{
						name: "Google Drive Folder",
						url: "https://drive.google.com/drive/folders/15RVWKXPOeME1RyhxC9vVHuIxEzv4ukMJ"
					},
					{
						name: "Test Data",
						url: "https://drive.google.com/drive/folders/16XoJnpOGy2YDBEA4aNwPfRrjvOer_aSo"
					},
				]
			},
	];

export default class TechnicalIndex extends Component {
	render() {
		return (
			<Layout showtitle={true} activePath='technical' pageTitle="Technical Documents" privatePage={true}>
				<GatedContent>
					<div className={"margin-content"}>
						<ul>
						{
							contentlist.map((heading,headingindex) => {
									return <li>{heading.name}<ul>
									{
										heading.sublist.map((data,index) => {
												return <li key={"listitem"+headingindex+"-"+index}>{data.url.substr(0,4) === "http"? 
												<a target="_blank" rel="noopener noreferrer" href={data.url}>{data.name}</a>
												:
												<Link to={data.url}>{data.name}</Link>}</li>;
										})
									}
									</ul></li>
							})
						}
						</ul>
					</div>
				</GatedContent>
			</Layout>
		)
	}
}



